@use 'ngx-sharebuttons/themes/default';

.sb-group {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;

  .sb-wrapper {

    width: 120px;

    .sb-icon {
      width: unset !important;
    }

    .sb-text {
      flex: none !important;
    }
  }
}


@font-face {
  font-family: 'Butler';
  src: url('assets/fonts/Butler_Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  scroll-behavior: smooth;
  overflow-anchor: none;
}

@layer base {
  .layout {
    @apply max-w-1920 mx-auto px-5 md:px-28 xl:px-60;
  }

  .unreset .ql-indent-1 {
    padding-left: 2.5rem;
  }

  .unreset .ql-indent-2 {
    padding-left: 3.5rem;
  }

  .unreset .ql-indent-3 {
    padding-left: 4.5rem;
  }

  .unreset .ql-indent-4 {
    padding-left: 5.5rem;
  }

  .unreset .ql-indent-5 {
    padding-left: 6.5rem;
  }

  .unreset ol {
    @apply list-decimal list-inside;
  }

  .unreset ul {
    @apply list-disc list-inside;
  }

  .unreset ul ul {
    @apply list-[circle] list-inside ml-4;
  }

  .unreset ol li,
  .unreset ul li {
    @apply ml-4;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .main-btn {
    --mouse-x: 50;
    --mouse-y: 50;
    @apply cursor-pointer text-white font-medium rounded-full block py-3 px-8 bg-100ciels-300 disabled:bg-gray-300;
    background-size: 200% 200%;
    background-position: calc((100 - var(--mouse-x, 0)) * 1%) calc((100 - var(--mouse-y, 0)) * 1%);
  }

  .big-btn {
    @apply px-6 py-5 mt-6 text-lg font-medium uppercase leading-normal w-full text-center rounded-full;
  }

  img {
    max-width: none !important;
  }

  .active {
    @apply font-bold
  }

  app-page h1 {
    @apply mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl
  }
}

.page h1 {
  @apply text-gray-500
}

.page p:empty {
  @apply mb-6
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.progress {
  animation: progress 1s infinite linear;
}

.left-right {
  transform-origin: 0 50%;
}

@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

@media screen and (max-width: 768px) {
  #axeptio_main_button {
    bottom: 5rem !important;

    &.product {
      bottom: 12rem !important;
    }
  }
}

@media screen and (min-width: 768px) {
  #axeptio_main_button {
    bottom: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .show-less {
    max-height: 110px;
    position: relative;
    overflow: hidden;
  }

  .show-less:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent, transparent, #FFF);
  }
}


h1, h2 {
  font-family: "Butler"
}

body, .font-montserrat {
  font-family: "Montserrat", sans-serif;
}

p {
  font-size: 1.1em;
}
